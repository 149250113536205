import * as React from "react"
import { graphql } from "gatsby"

import MainView  from "../views/main-view"

const AddressPage = (props) => {
  const { allCards } = props.data;

  console.log(allCards)

  // pull out state from props.location, so we set filters appropriately on forward/back browser navigation
  const artistFilter = props.location.state ? props.location.state.artistFilter : null;
  const sort = props.location.state ? props.location.state.sort : null;

  return <MainView allCards={allCards} selectedAddress={props["*"]} sort={sort} location={props.location} />
}

export default AddressPage

export const query = graphql`
  query {
    allCards: allCardsJson {
      nodes {
        Number
        Artist
        Series
        Decimals
        Card
        Creation_Date
        Contract
        Name
        Symbol
        Supply
        Description
        IPFS
        Image
        IPFS_hash
        Locked
        Type
      }
    }
  }
`
